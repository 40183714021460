<template lang='pug'>
v-card(v-if="Object.keys(manager).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ ($t(`crewing-manager-${Object.keys(manager?.behavior)[0]}`, { nameCompany: manager?.crewing_company.name_ukr })).toUpperCase() }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text
    v-divider
    SailorCrewingManagerInfo(v-if="manager?.behavior?.viewInfoBlock" :sailorDocument="manager")
    SailorCrewingManagerEdit(v-else-if="manager?.behavior?.viewEditBlock" :sailorDocument="manager")
    ViewPhotoList(
      v-else-if="manager?.behavior?.viewFilesBlock"
      isHiddenActionsButton
      :sailorDocument="manager"
      documentType="manager")
</template>
<script>
import { checkAccess } from '@/mixins/permissions'
import { viewDetailedComponent } from '@/mixins/main'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'BackOfficeCrewingManager',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    SailorCrewingManagerInfo: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingManagers/BackOfficeCrewingManagerInfo/BackOfficeCrewingManagerInfo.vue'),
    SailorCrewingManagerEdit: () => import('@/views/BackOffice/BackOfficeCrewing/BackOfficeCrewingManagers/BackOfficeCrewingManagerEdit/BackOfficeCrewingManagerEdit.vue')
  },
  data () {
    return {
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.manager, 'viewInfoBlock'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.manager, 'viewEditBlock'),
          checkAccess: () => checkAccess('backOfficeCrewingManager', 'edit', this.manager),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-trash-can-outline',
          action: () => this.removeCrewingManager(),
          checkAccess: () => checkAccess('backOfficeCrewingManager', 'removeCrewingManager', this.manager),
          color: 'error',
          tooltip: 'documentIconControl.delete',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 4,
          name: 'mdi-close',
          action: () => this.$router.go(-1),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      manager: state => state.crewing.crewingManagerById,
      isLoading: state => state.crewing.isLoading
    })
  },
  beforeMount () {
    this.getData()
  },
  methods: {
    ...mapActions(['getCrewingCompanyManagerById', 'removeCrewingManagerById']),
    getData () {
      const { managerID } = this.$route.params
      this.getCrewingCompanyManagerById({ managerID })
    },
    removeCrewingManager () {
      const { managerID } = this.$route.params

      this.$swal({
        title: this.$t('warning'),
        text: this.$t('removeCrewingManagerConfirmation'),
        icon: 'warning',
        buttons: [this.$t('cancel'), this.$t('confirm')],
        dangerMode: true
      }).then(async confirmation => {
        if (confirmation) {
          const response = await this.removeCrewingManagerById({ managerID })
          if ([200, 204].includes(response.code)) {
            this.$notification.success('notify.success.disconnectManagerWithCompany')
            this.pageBack()
          }
        }
      })
    },

    pageBack () {
      if (checkAccess('backOffice')) {
        this.$router.push({ name: 'crewing-managers-backoffice', params: { groupID: this.$route.params.groupID, crewingID: this.$route.params.companyID } })
      }
    }
  }
}
</script>
